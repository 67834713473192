<template>
<v-dialog v-model="dialog.turno_nulo" persistent max-width="450" transition="fade-transition" scrollable overlay-opacity=".25">
    <v-card>
        <v-card-text class="pa-0 rounded-lg">
            <div v-if="skeleton">
                <v-skeleton-loader type="list-item-three-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line" />
            </div>
            <v-card-title class="pa-0 modal-title" v-if="!skeleton">
                <v-toolbar flat height="62" class="rounded-t-lg">
                    <v-list-item class="px-0">
                        <v-list-item-avatar class="mr-2 rounded-lg" color="secondary" tile width="8" height="30" min-width="8" />
                        <v-list-item-content>
                            <v-list-item-title class="headline mb-0">
                                <v-toolbar-title class="pa-0 text-capitalize grey--text text--darken-2 trenos-title-toolbar" style="line-height: 1.1;">Turnos nulos</v-toolbar-title>
                            </v-list-item-title>
                            <v-list-item-subtitle class="font-weight-medium mb-0">
                                <span class="text-capitalize">Lista</span>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="CloseDialog('turno_nulo')" class="mr-0">
                        <v-icon size="20">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-card flat class="text-center pa-3 mx-auto py-4 px-6" max-width="300">
                                <v-menu v-model="fecha_menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="fecha" label="Selecciona un fecha" readonly v-bind="attrs" v-on="on" dense clearable hide-details outlined />
                                    </template>
                                    <v-date-picker v-model="fecha" @input="fecha_menu = false" first-day-of-week="1" no-title scrollable :allowed-dates="FechaLista" show-current @change="GetLista()"></v-date-picker>
                                </v-menu>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col>
                            <!--<div v-if="nulo_lista_turnos.length" class="text-center pb-3">
                                <v-btn depressed color="primary">
                                    quitar anulacion todos ({{nulo_lista_turnos.length}})
                                </v-btn>
                            </div>-->
                            <v-list>
                                <v-list-item flat v-for="turno in nulo_lista_turnos" :key="turno.id" class="rounded-lg" link>
                                    <v-list-item-content class="py-0 rounded-lg">
                                        <v-list-item class="rounded-lg">
                                            <v-list-item-avatar class="my-1 mr-2" size="30">
                                                <v-avatar color="grey lighten-4" size="30">
                                                    <v-icon size="15" color="grey">mdi-text-box-multiple-outline</v-icon>
                                                </v-avatar>
                                            </v-list-item-avatar>
                                            <v-list-item-content class="py-0">
                                                <v-list-item-title class="text-subtitle-2 grey--text text--darken-1 text-capitalize">Turno {{turno.turno}}</v-list-item-title>
                                                <v-list-item-subtitle class="text-capitalize">
                                                    {{ TipoTurno(turno.tipo) }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action class="my-0 avatar-otros">
                                                <v-btn icon @click="QuitarNulo(turno.id)">
                                                    <v-icon color="red">mdi-delete-outline</v-icon>
                                                </v-btn>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card-text>
    </v-card>
</v-dialog>
</template>

<script>
import dayjs from 'dayjs';
import {
    mapState,
    mapMutations
} from "vuex";
export default {
    name: 'TurnoNuloLista',
    data() {
        return {
            skeleton: false,
            fecha_menu: false,
            fecha: null,
            nulo_lista_fechas: [],
            nulo_lista_turnos: [],
            nulo_elegidos: []
        }
    },
    computed: {
        ...mapState(['dialog', 'usuario', 'update']),
        DialogComponente() {
            return this.dialog.turno_nulo
        }
    },
    methods: {
        ...mapMutations(["ShowSnackBar", "ErrorRequest", 'OpenDialog', 'CloseDialog']),
        //Obtiene la lista de fechas en la cuales existen turnos nulos
        async GetData() {
            this.nulo_lista_turnos = []
            this.fecha = null
            await this.axios.get(`/buscar/turno/nulo`, {
                    headers: {
                        token: this.usuario.token,
                    },
                })
                .then((respuesta) => {
                    if (respuesta.data.mensaje) {
                        this.ErrorRequest(respuesta.data.mensaje)
                    }
                    if (respuesta.data.lista) {
                        this.nulo_lista_fechas = respuesta.data.lista
                    }
                })
                .catch((err) => {
                    //this.overlay = false
                    this.ErrorRequest(err)
                });
        },
        //Genera la lista de fechas con turnos nulos para el datepicker
        FechaLista(val) {
            for (var i = 0; i < this.nulo_lista_fechas.length; i++) {
                if (this.nulo_lista_fechas[i] == val) {
                    return val
                }
            }
        },
        //Obtiene los turnos nulos dentro de la fecha seleccionada.
        async GetLista() {
            if (this.fecha) { 
                let fecha_axios = dayjs(this.fecha).format('YYYYMMDD')
                await this.axios.get(`/buscar/turno/nulo/${fecha_axios}`, {
                        headers: {
                            token: this.usuario.token,
                        },
                    })
                    .then((respuesta) => {
                        if (respuesta.data.mensaje) {
                            this.ErrorRequest(respuesta.data.mensaje)
                        }
                        if (respuesta.data.lista) {
                            this.nulo_lista_turnos = []
                            this.nulo_lista_turnos = respuesta.data.lista
                        }
                    })
                    .catch((err) => {
                        //this.overlay = false
                        this.ErrorRequest(err)
                    });
            }
        },
        //Envia el o las id's de los turnos nulos para normalizarlos (quitarles nulidad)
        async QuitarNulo(id) {
            let datos = {}
            if (id) datos.lista = [id]
            else datos.lista = JSON.parse(JSON.stringify(this.nulo_lista_turnos))
            await this.axios.post(`/buscar/turno/edit/nulo`, datos, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                }
                this.GetLista()
            }).catch(err => {
                this.ErrorRequest(err)
            })
        },
        //Convierte la sigla del tipo de turno en su nombre respectivo.
        TipoTurno(tipo) {
            let turno_tipo
            switch (tipo) {
                case 'fm':
                    turno_tipo = 'Full Time Mañana'
                    break;
                case 'ft':
                    turno_tipo = 'Full Time Tarde'
                    break;
                case 'lm':
                    turno_tipo = 'Part Time 80 Mañana'
                    break;
                case 'lt':
                    turno_tipo = 'Part Time 80 Tarde'
                    break;
                case 'lms':
                    turno_tipo = 'Part Time 120 Mañana'
                    break;
                case 'lts':
                    turno_tipo = 'Part Time 120 Tarde'
                    break;
                case 'sm':
                    turno_tipo = 'Sabado Mañana'
                    break;
                case 'st':
                    turno_tipo = 'Sabado Tarde'
                    break;
                case 'dm':
                    turno_tipo = 'Domingo Mañana'
                    break;
                case 'dt':
                    turno_tipo = 'Domingo Tarde'
                    break;
            }
            return turno_tipo
        },
    },
    mounted() {
        //this.GetData()
    },
    watch: {
        DialogComponente: function (val) {
            if (val) {
                this.GetData()
            }
        }
    }
}
</script>

<style>

</style>
